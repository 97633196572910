/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// Custom styles for this theme
@import "theme";

// Some temp fixes
//@import "fixes";

// If you want to add custom CSS you can put it here.
@import "custom";

// Examples
// We use those styles to show code examples, you should remove them in your application.
@import "examples";



html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.mat-typography {
    font: 400 16px 'Roboto', 'Helvetica Neue', sans-serif !important;
    font-family:  'Roboto', 'Helvetica Neue', sans-serif !important;
  }

.dropdown-menu.show {
    max-height: 300px;
    overflow: auto;
}


.quick-filter {
    .form-control {
        display: inline-block;
        width: 300px;
        margin: 10px;
    }
}
.cursor{
    cursor: pointer;
}
.badge {
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    display: inline-block;
    min-width: 65px;
    margin: 8px auto;
    // font-weight: 600;
  }
  
  .badge-success {
    background-color: #c3fed0;
    color: #28a745;
  }
  
  .badge-warning {
    background-color: #ffe187;
    color: #a37c07;
  }
  
  .badge-danger {
    background-color: #ffcdd2;
    color: #dc3545;
  }
  .top-align {
    position: absolute;
    margin-top: 65px;
}

.toggle-bar {
  .form-switch {
      padding-left: 2.8em;
  }

  .form-check-input {
      width: 3em;
      height: 1.6em;
      background-color: #ddd;
      border-radius: 15px;
      position: relative;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }

  .form-check-input:checked {
      background-color: #0d6efd;
  }

  .form-check-input::before {
      content: "";
      position: absolute;
      width: 1.3em;
      height: 1.3em;
      background-color: white;
      border-radius: 50%;
      top: 0.1em;
      left: 0.1em;
      transition: transform 0.3s ease;
  }

  .form-check-input:checked::before {
      transform: translateX(1.5em);
  }


}

.arrow-alignment{
    margin-top:7px;
    margin-left:8px;
  }

  
.right-control {
    border-radius: 5px;
    margin-left: auto;
  }
  
  .input-select{
    width: 300px;
    margin-left:50px;
    padding-left: 10px;
}

.label{
    text-align: left;
    width: 180px;
    display: block; 
    padding-left: 25px;
}


input[type="range"] {
  width: 40%;
}

.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}

h4, .h4, h5, .h5{
    font-size: 1.5rem !important;
    font-weight: 500 !important;
}

h6,.h6{
  font-size: 1rem !important;
  font-weight: 500 !important;
}

$light-theme: mat.define-light-theme(
  mat.define-palette(mat.$indigo-palette),
  mat.define-palette(mat.$pink-palette)
);

.light-theme{
@include mat.all-component-themes($light-theme);
}

$dark-theme: mat.define-dark-theme(
  mat.define-palette(mat.$indigo-palette),
  mat.define-palette(mat.$pink-palette)
);
/* Dark Theme */
.dark-theme {
  @include mat.all-component-themes($dark-theme);
  --mat-stepper-container-color: #222222 !important;
}
