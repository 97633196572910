// Variable overrides
//
// If you want to customize your project please add your variables below.

$enable-deprecation-messages: false !default;

// theme

$dark-background: #232323;
$white-text : #ffffff;
$dark-text: #001225;
$link-color: #232343;
$bg-gray : #F4F4F4;